export const plantList = [
	{
	  name: 'onstera',
	  category: 'classique',
	  id: '1ed',
	  isBestSale: false,
	  isOnPromotion: false,
	},
	{
	  name: 'ficus lyrata',
	  category: 'classique',
	  id: '2ab',
	  isBestSale: false,
	  isOnPromotion: true,
	},
	{
	  name: 'pothos argenté',
	  category: 'classique',
	  id: '3sd',
	  isBestSale: true,
	  isOnPromotion: false,
	},
	{
	  name: 'yucca',
	  category: 'classique',
	  id: '4kk',
	  isBestSale: false,
	  isOnPromotion: true,
	},
	{
	  name: 'olivier',
	  category: 'extérieur',
	  id: '5pl',
	  isBestSale: false,
	  isOnPromotion: false,
	},
	{
	  name: 'géranium',
	  category: 'extérieur',
	  id: '6uo',
	  isBestSale: true,
	  isOnPromotion: true,
	},
	{
	  name: 'basilique',
	  category: 'extérieur',
	  id: '7ie',
	  isBestSale: false,
	  isOnPromotion: false,
	},
	{
	  name: 'aloe',
	  category: 'plante grasse',
	  id: '8fp',
	  isBestSale: true,
	  isOnPromotion: false,
	},
	{
	  name: 'ucculente',
	  category: 'plante grasse',
	  id: '9vn',
	  isBestSale: false,
	  isOnPromotion: true,
	},
  ];