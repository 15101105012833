import '../styles/App.css';
import Banner from './Banner';
import Cart from './Cart';
import ShoppingList from './ShoppingList';

function App() {
  return (
    <div>
      <Banner/>
      <Cart/>
      <ShoppingList/>
    </div>
  );
}

export default App;
