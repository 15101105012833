import { plantList } from '../datas/plantList'
import '../styles/ShoppingList.css'

function ShoppingList() {
    const categories = [...new Set(plantList.map(plant => plant.category))];
    return (
        <div>
        <h2>Listes des catégories de plantes</h2>
        <ul>    
            {categories.map((category, index) => (
                <li key={`${category}-${index}`}>{category}</li>
            ))}
        </ul>
        <h2>Listes des plantes</h2>
        <ul>
            {plantList.map((plant) => (
                <li key={`${plant.id}`}>
                    {plant.name} {plant.isBestSale && <span>🔥</span>} {plant.isOnPromotion && <small className='lmj-soldes'>Soldes</small>}
                </li>
            ))}
        </ul>
        </div>
    )
}

export default ShoppingList